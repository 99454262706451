import { Stack, Typography } from "@mui/material";
import HeightInput from "../HeightInput";
import FormInputErrorList from "../FormInputErrorList";
import { useTranslation } from "react-i18next";
import { convertLength } from "../../utils";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface Props {
  onChange: (data: any) => void;
  errors?: Array<string>;
  data?: ValuePayload;
}

enum Unit {
  CM = "CM",
  M = "M",
}

interface ValuePayload {
  value: string;
  unit: Unit.CM | Unit.M;
}

export default function QuestionTypeHeight({ onChange, data, errors }: Props) {
  const { t } = useTranslation();

  function isValid(valuePayload: ValuePayload) {
    return valuePayload.value && valuePayload.unit ? true : false;
  }

  function normaliseInputValue(valuePayload: ValuePayload) {
    if (valuePayload.unit === Unit.M) {
      return {
        value: convertLength(
          Unit.M,
          Unit.CM,
          Number(valuePayload.value)
        ).toString(),
        unit: Unit.CM,
      };
    }
    return valuePayload;
  }

  function onHeightInputChange(valuePayload: ValuePayload) {
    onChange({
      data: normaliseInputValue(valuePayload),
      isValid: isValid(valuePayload),
    });
    if (isValid(valuePayload)) {
      trackUserInteraction({
        linkText: `Edit Height | ${normaliseInputValue(valuePayload).value} ${normaliseInputValue(valuePayload).unit}`,
        linkIntent: "confirmational",
        linkScope: "button",
      });
    }
  }

  return (
    <Stack spacing={2} justifyContent="space-between">
      <FormInputErrorList errors={errors || []} />
      <Typography variant="h2">{t("QuestionTypeHeight.title")}</Typography>
      <Typography color={(theme) => theme.palette.neutral[500]}>
        {t("QuestionTypeHeight.subtitle")}
      </Typography>

      <HeightInput data={data} onChange={onHeightInputChange} />
    </Stack>
  );
}
