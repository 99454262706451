import { Button, Stack, Typography } from "@mui/material";
import { convertPxToRem } from "../../../../utils";
import theme, { shadows } from "../../../../theme";
import FullscreenBackground from "../../../FullscreenBackground";
import { useTranslation } from "react-i18next";
import MaxWidthContainer from "../../../MaxWidthContainer";
import IconLoader from "../../../IconLoader";
import Panel from "../../../Panel";
import { useNavigate } from "react-router";
import { trackEvent } from "../../../../services/analytics-adapter";

interface FaceScanCompleteProps {
  scanResults: any;
  onDone: () => void;
}

interface BottomButtonGroupProps {
  onDone: () => void;
}

function BottomButtonGroup({ onDone }: BottomButtonGroupProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  function onGoHomeButtonClick() {
    trackEvent({
      event: "action.healthCheckCompleteGoHome",
      source: "Health check",
    });
    onDone();
  }

  function onViewHealthProfileClick() {
    trackEvent({
      event: "action.healthCheckCompleteViewHealthProfile",
      source: "Health check",
    });
    return navigate("/home/my-health/health-profile");
  }

  return (
    <Stack
      direction={"row"}
      spacing={1}
      p={2}
      bgcolor="background.paper"
      width="100%"
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      left="0"
      bottom="0"
      position="fixed"
      sx={{
        boxShadow: shadows.heavy,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      }}
    >
      <MaxWidthContainer>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Button
            onClick={onGoHomeButtonClick}
            color="primary"
            variant="outlined"
            fullWidth
          >
            {t("BinahFaceScanSuccess.goHomeButton")}
          </Button>
          <Button onClick={onViewHealthProfileClick} fullWidth>
            {t("BinahFaceScanSuccess.viewHealthProfile")}
          </Button>
        </Stack>
      </MaxWidthContainer>
    </Stack>
  );
}

export default function FaceScanComplete({
  scanResults,
  onDone,
}: FaceScanCompleteProps) {
  const { t } = useTranslation();

  const isBloodPressureAvailable =
    Boolean(scanResults?.bloodPressure) &&
    Boolean(scanResults?.bloodPressure?.value?.systolic) &&
    Boolean(scanResults?.bloodPressure?.value?.diastolic);

  const isHeartRateAvailable =
    Boolean(scanResults?.heartRate) && Boolean(scanResults?.heartRate?.value);

  const processedScanResults = [
    {
      value: `${scanResults?.bloodPressure?.value?.systolic}/${scanResults?.bloodPressure?.value?.diastolic}`,
      shouldShow: isBloodPressureAvailable,
      code: "BLOOD_PRESSURE",
    },
    {
      value: scanResults?.heartRate?.value,
      shouldShow: isHeartRateAvailable,
      code: "PULSE",
    },
  ];

  const shouldShowResults = processedScanResults.some(
    (resultEntry) => resultEntry.shouldShow === true
  );

  return (
    <Stack color="primary.contrastText" justifyContent="flex-end">
      <Stack
        sx={{
          zIndex: 1,
          position: "relative",
        }}
        bgcolor="primary.main"
        textAlign="center"
        p={4}
        spacing={2}
        px={6}
      >
        <IconLoader
          style={{
            alignSelf: "center",
            fontSize: shouldShowResults
              ? convertPxToRem(54)
              : convertPxToRem(135),
          }}
          color="primary"
          icon={"FaceScanInverse"}
        />
        <Typography variant="h2" color="inherit">
          {t("BinahFaceScanSuccess.title")}
        </Typography>

        {shouldShowResults && (
          <>
            {(
              t<any, any, string[]>("BinahFaceScanSuccess.body", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography
                key={String(text + index)}
                variant="h3"
                fontWeight={600}
                color="inherit"
                p={2}
              >
                {text}
              </Typography>
            ))}
          </>
        )}
      </Stack>

      {shouldShowResults && (
        <>
          <Stack
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              backgroundColor: "neutral.50",
              position: "relative",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              p: 2,
              pb: 15,
              zIndex: 2,
              textAlign: "center",
              top: -16,
            }}
          >
            <Typography pb={2} variant="h3" fontWeight={600}>
              {t("BinahFaceScanSuccess.resultsTitle")}
            </Typography>
            {(processedScanResults || []).map((result: any, index: number) => (
              <Stack my={1} key={result.code + index}>
                {result?.shouldShow && (
                  <Panel>
                    <Stack spacing={2} justifyContent="space-between">
                      <Stack direction="row" alignItems="center">
                        <IconLoader
                          style={{
                            alignSelf: "center",
                            fontSize: convertPxToRem(34),
                            marginRight: 2,
                          }}
                          color="primary"
                          icon={"HealthPin"}
                        />
                        <Typography
                          variant="h2"
                          fontWeight={600}
                          color={(theme) => theme.palette.primary[700]}
                        >
                          {result?.value}
                        </Typography>
                      </Stack>
                      <Stack textAlign="left" spacing={1}>
                        <Typography variant="h4" fontWeight={600}>
                          {t(
                            `BinahFaceScanSuccess.results.${result?.code}.label` as any
                          )}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={(theme) => theme.palette.grey[700]}
                        >
                          {t(
                            `BinahFaceScanSuccess.results.${result?.code}.description` as any
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Panel>
                )}
              </Stack>
            ))}
            <BottomButtonGroup onDone={onDone} />
          </Stack>

          <FullscreenBackground color="neutral.50" />
        </>
      )}

      {!shouldShowResults && (
        <>
          {(
            t<any, any, string[]>("BinahFaceScanSuccess.noResults.body", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              textAlign="center"
              key={String(text + index)}
              variant="h3"
              color="inherit"
              p={2}
            >
              {text}
            </Typography>
          ))}
          <Stack pb={20}>
            <BottomButtonGroup onDone={onDone} />
          </Stack>
          <FullscreenBackground color="primary.main" />
        </>
      )}
    </Stack>
  );
}
