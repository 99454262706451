import {
  getMemberships,
  getMembershipConfig,
  MembershipConfig,
  HealthCheckQuestionnaireData,
  submitFaceScanMeasurements,
  submitMemberAcknowledgeEvent,
  getServiceOfferingUsage,
  ServiceOfferingUsage,
  getAvailableHealthCheckCount,
} from "../../services/core-api-adapter";

import { setup, assign, fromPromise } from "xstate";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "../../services/feature-toggle-adapter";
import { isFaceScanCompatibleDevice } from "../../utils";

export const eventNames = {
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  TRY_AGAIN_BUTTON: "TRY_AGAIN_BUTTON",
  DECLINE_BUTTON_CLICKED: "DECLINE_BUTTON_CLICKED",
};

interface Context {
  membershipConfig: MembershipConfig | null;
  shouldSkipScanPrompt: boolean;
  availableServiceOfferingUsageCount: number;
  serviceOfferingUsageList: ServiceOfferingUsage[];
  healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  deviceCompatibilityError: ErrorMap | null;
}

const initialContextValues: Context = {
  membershipConfig: null,
  healthCheckQuestionnaireData: null,
  shouldSkipScanPrompt: false,
  availableServiceOfferingUsageCount: 0,
  serviceOfferingUsageList: [],
  deviceCompatibilityError: null,
};

enum ErrorMap {
  UNSUPPORTED_BROWSER_IOS = "UNSUPPORTED_BROWSER_IOS",
  UNSUPPORTED_BROWSER_ANDROID = "UNSUPPORTED_BROWSER_ANDROID",
}

export const binahScanFlowStateMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addMembershipsResponseToContext: assign({
      membershipConfig: ({ event }) => {
        const firstMembership = event.output?.memberships[0];

        return getMembershipConfig(firstMembership?.productDetails.code);
      },
    }),
    addServiceOfferingUsageResponseToContext: assign({
      availableServiceOfferingUsageCount: ({ event }) => {
        return getAvailableHealthCheckCount(event.output.body);
      },
      serviceOfferingUsageList: ({ event }) => {
        return event.output.body;
      },
    }),
    addHealthCheckQuestionnaireDataToContext: assign(({ event }) => {
      return { healthCheckQuestionnaireData: event.input };
    }),

    addDeviceCompatibilityErrorToContext: assign({
      deviceCompatibilityError: () => {
        const isIOSWithNonSafariBrowser =
          isFaceScanCompatibleDevice().isIOSWithNonSafariBrowser;
        const isAndroidWithNonChromeOrSamsungBrowser =
          isFaceScanCompatibleDevice().isAndroidWithNonChromeOrSamsungBrowser;

        if (isIOSWithNonSafariBrowser) {
          return ErrorMap.UNSUPPORTED_BROWSER_IOS;
        }

        if (isAndroidWithNonChromeOrSamsungBrowser) {
          return ErrorMap.UNSUPPORTED_BROWSER_ANDROID;
        }

        return null;
      },
    }),
  },
  actors: {
    getMemberships: fromPromise(async () => {
      return await getMemberships();
    }),
    getServiceOfferingUsage: fromPromise(async () => {
      return await getServiceOfferingUsage();
    }),
    submitFaceScanMeasurements: fromPromise(
      async ({
        input,
      }: {
        input: {
          healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
        };
      }) => {
        return await submitFaceScanMeasurements({
          vitalSigns: null,
          healthCheckQuestionnaireData: input.healthCheckQuestionnaireData,
        });
      }
    ),
    submitMemberAcknowledgementFaceScanStarted: fromPromise(async () => {
      return await submitMemberAcknowledgeEvent("FACE_SCAN_STARTED");
    }),
    submitMemberAcknowledgementFaceScanSkipped: fromPromise(async () => {
      return await submitMemberAcknowledgeEvent("FACE_SCAN_SKIPPED");
    }),
  },
  guards: {
    shouldShowProductRules: ({ context }) => {
      const {
        isIOSWithNonSafariBrowser,
        isAndroidWithNonChromeOrSamsungBrowser,
      } = isFaceScanCompatibleDevice();
      const isDeviceCompatible = !(
        isIOSWithNonSafariBrowser || isAndroidWithNonChromeOrSamsungBrowser
      );

      return Boolean(
        isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_FACE_SCAN_PRODUCT_RULES
        ) &&
          context.membershipConfig?.isIntercareSupported === true &&
          isDeviceCompatible
      );
    },
    userHasNoUnlimitedHealthCheck: ({ context }) => {
      const isUnlimitedHealthCheckAvailable =
        context.serviceOfferingUsageList.find(
          (serviceOffering) => serviceOffering.unlimited === true
        ) || null;
      return Boolean(!isUnlimitedHealthCheckAvailable);
    },
    shouldSkipScanPrompt: ({ context }) => {
      return context.shouldSkipScanPrompt;
    },
    userHasIncompatibleDevice: () => {
      const {
        isIOSWithNonSafariBrowser,
        isAndroidWithNonChromeOrSamsungBrowser,
      } = isFaceScanCompatibleDevice();

      return (
        isIOSWithNonSafariBrowser || isAndroidWithNonChromeOrSamsungBrowser
      );
    },
  },
  schemas: {
    events: {
      CONTINUE_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      GO_HOME_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
      TRY_AGAIN_BUTTON: {
        type: "object",
        properties: {},
      },
      DECLINE_BUTTON_CLICKED: {
        type: "object",
        properties: {},
      },
    },
  },
}).createMachine({
  id: "binahScanFlow",
  context: initialContextValues,
  initial: "fetchingMemberships",
  states: {
    fetchingMemberships: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        if (event.input.shouldSkipScanPrompt) {
          entryContext.shouldSkipScanPrompt = event.input.shouldSkipScanPrompt;
        }
        return entryContext;
      }),
      invoke: {
        id: "fetchingMemberships",
        input: {},
        onDone: {
          target: "decideNextStateFromFetchingMemberships",
          actions: "addMembershipsResponseToContext",
        },
        onError: {
          target: "error",
        },
        src: "getMemberships",
      },
    },

    fetchingServiceOfferingUsage: {
      invoke: {
        id: "fetchingServiceOfferingUsage",
        input: {},
        onDone: {
          target: "shouldShowPreScanQuestionnareOrProductRules",
          actions: "addServiceOfferingUsageResponseToContext",
        },
        onError: {
          target: "error",
        },
        src: "getServiceOfferingUsage",
      },
    },

    decideNextStateFromFetchingMemberships: {
      always: [
        {
          guard: { type: "shouldSkipScanPrompt" },
          target: "showFetchServiceOfferingUsageOrPreScanQuestionnaire",
        },
        {
          target: "showScanPrompt",
        },
      ],
    },

    showScanPrompt: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "showFetchServiceOfferingUsageOrPreScanQuestionnaire",
        },
        [eventNames.DECLINE_BUTTON_CLICKED]: {
          target: "submittingFaceScanSkippedAcknowledgementEvent",
        },
      },
    },

    shouldShowPreScanQuestionnareOrProductRules: {
      always: [
        {
          target: "showProductRules",
          guard: {
            type: "userHasNoUnlimitedHealthCheck",
          },
        },
        {
          target: "showPreScanQuestionnaire",
        },
      ],
    },

    showFetchServiceOfferingUsageOrPreScanQuestionnaire: {
      always: [
        {
          target: "fetchingServiceOfferingUsage",
          guard: { type: "shouldShowProductRules" },
        },
        {
          target: "showPreScanQuestionnaire",
        },
      ],
    },

    showProductRules: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "showPreScanQuestionnaire",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },

    showPreScanQuestionnaire: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "submittingHealthCheckQuestionnaireData",
          actions: "addHealthCheckQuestionnaireDataToContext",
        },
      },
    },

    submittingHealthCheckQuestionnaireData: {
      invoke: {
        id: "submittingHealthCheckQuestionnaireData",
        src: "submitFaceScanMeasurements",
        input: ({ context }) => {
          return {
            healthCheckQuestionnaireData: context.healthCheckQuestionnaireData,
          };
        },
        onDone: {
          target: "showPreScanInstructions",
        },
        onError: {
          target: "error",
        },
      },
    },

    showPreScanInstructions: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "submittingFaceScanStartedAcknowledgementEvent",
        },
        [eventNames.DECLINE_BUTTON_CLICKED]: {
          target: "submittingFaceScanSkippedAcknowledgementEvent",
        },
      },
    },

    submittingFaceScanStartedAcknowledgementEvent: {
      invoke: {
        src: "submitMemberAcknowledgementFaceScanStarted",
        onDone: {
          target: "startingScanOrDisplayingDeviceCompatibilityError",
        },
        onError: {
          target: "startingScanOrDisplayingDeviceCompatibilityError",
        },
      },
    },

    submittingFaceScanSkippedAcknowledgementEvent: {
      invoke: {
        src: "submitMemberAcknowledgementFaceScanSkipped",
        onDone: {
          target: "scanCompleted",
        },
        onError: {
          target: "scanCompleted",
        },
      },
    },

    startingScanOrDisplayingDeviceCompatibilityError: {
      always: [
        {
          guard: { type: "userHasIncompatibleDevice" },
          target: "showDeviceCompatibilityError",
          actions: "addDeviceCompatibilityErrorToContext",
        },
        {
          target: "startingScan",
        },
      ],
    },

    showDeviceCompatibilityError: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },

    startingScan: {
      on: {
        [eventNames.TRY_AGAIN_BUTTON]: {
          target: "showScanPrompt",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.DECLINE_BUTTON_CLICKED]: {
          target: "submittingFaceScanSkippedAcknowledgementEvent",
        },
      },
    },

    error: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },

    scanCompleted: {
      type: "final",
    },

    exit: {
      type: "final",
    },
  },
});
