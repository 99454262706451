import FullscreenLoadingIndicator from "@/components/FullscreenLoadingIndicator";
import QuestionTypeDateOfBirth from "@/components/QuestionTypeDateOfBirth";
import QuestionTypeHeight from "@/components/QuestionTypeHeight";
import QuestionTypeSexAtBirth from "@/components/QuestionTypeSexAtBirth";
import QuestionTypeSmokingStatus from "@/components/QuestionTypeSmokingStatus";
import QuestionTypeWeight from "@/components/QuestionTypeWeight";
import useGetHealthProfile from "@/hooks/useGetHealthProfile";
import {
  AnalyticsEvent,
  trackEvent,
  trackFormEvent,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import { FaceIconBrandLarge } from "@/theme/icons";
import { convertStringToJSON, isObjectEmpty } from "@/utils";
import { Stack, Button } from "@mui/material";
import { useState, useEffect, createElement } from "react";
import { useTranslation } from "react-i18next";
import PreScanQuestionnaireMoreInfoModal from "./components/PreScanQuestionnaireMoreInfoModal";

interface PreScanQuestionnaireFlowProps {
  onComplete: (data: any) => void;
  onNextQuestion: (questionIndex: number) => void;
}

type QuestionTypeName =
  | "DateOfBirth"
  | "SexAtBirth"
  | "Height"
  | "Weight"
  | "SmokingStatus";

interface QuestionTypesMetadata {
  [key: string]: {
    questionType: QuestionTypeName;
    component: any;
    shouldAutoSubmit: boolean;
    onChange: (value: any) => void;
  };
}

export default function PreScanQuestionnaireFlow({
  onComplete,
  onNextQuestion,
}: PreScanQuestionnaireFlowProps) {
  const { t } = useTranslation();

  const { healthProfile, isHealthProfileLoading } = useGetHealthProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionsToAnswer, setQuestionsToAnswer] = useState<string[]>([]);

  function onModalOpen() {
    const currentQuestion = questionsToAnswer[0];
    trackEvent({
      event: "action.healthCheckQuestionWhyAreWeAskingYouThisModalOpened",
      questionType: currentQuestion,
      source: "Health check",
    });
    setIsModalOpen(true);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  const [isValidInput, setIsValidInput] = useState(false);
  const [healthCheckQuestionnaireData, setHealthCheckQuestionnaireData] =
    useState<any>({});

  const questionTypesMapping: QuestionTypesMetadata = {
    SexAtBirth: {
      questionType: "SexAtBirth",
      component: QuestionTypeSexAtBirth,
      shouldAutoSubmit: true,
      onChange: onSexAtBirthChange,
    },
    Height: {
      questionType: "Height",
      component: QuestionTypeHeight,
      shouldAutoSubmit: true,
      onChange: onHeightChange,
    },
    Weight: {
      questionType: "Weight",
      component: QuestionTypeWeight,
      shouldAutoSubmit: false,
      onChange: onWeightChange,
    },
    DateOfBirth: {
      questionType: "DateOfBirth",
      component: QuestionTypeDateOfBirth,
      shouldAutoSubmit: true,
      onChange: onDateOfBirthChange,
    },
    SmokingStatus: {
      questionType: "SmokingStatus",
      component: QuestionTypeSmokingStatus,
      shouldAutoSubmit: false,
      onChange: onSmokingStatusChange,
    },
  };

  useEffect(() => {
    const outstandingQuestions: QuestionTypeName[] = [];
    let currentSubjectDemographic: any = {};

    Object.keys(questionTypesMapping).forEach((item) => {
      const questionTypeDataFromHealthProfile =
        getQuestionTypeDataFromHealthProfileResponse(item);

      const transformedQuestionType =
        item.charAt(0).toLowerCase() + item.slice(1);

      if (questionTypeDataFromHealthProfile) {
        currentSubjectDemographic = {
          ...currentSubjectDemographic,
          [transformedQuestionType]:
            questionTypeDataFromHealthProfile?.hasOwnProperty("value")
              ? questionTypeDataFromHealthProfile
              : { value: questionTypeDataFromHealthProfile },
        };
      }

      const { shouldAutoSubmit } = questionTypesMapping[item];

      if (
        !currentSubjectDemographic[transformedQuestionType] ||
        shouldAutoSubmit === false
      ) {
        outstandingQuestions.push(item as QuestionTypeName);
      }
    });

    setHealthCheckQuestionnaireData(currentSubjectDemographic);
    setQuestionsToAnswer(outstandingQuestions);

    if (!outstandingQuestions.length) {
      onComplete(currentSubjectDemographic);
    }
  }, [healthProfile]);

  function getQuestionTypeComponent() {
    const currentQuestion = questionsToAnswer[0];
    const { component, onChange } = questionTypesMapping[currentQuestion];

    return createElement(component, {
      onChange: onChange,
      data: getQuestionTypeDataFromHealthProfileResponse(currentQuestion),
    });
  }

  function getQuestionTypeDataFromHealthProfileResponse(question: string) {
    const data = convertStringToJSON(
      healthProfile?.items.find(
        (healthProfileItem) => healthProfileItem.questionType === question
      )?.data || ""
    );

    if (!data || isObjectEmpty(data)) {
      return null;
    }
    return data;
  }

  function onSexAtBirthChange(value: any) {
    setIsValidInput(false);
    if (value.data.value) {
      let transformedValue = value.data.value;

      if (transformedValue?.toUpperCase() === "MALE") {
        transformedValue = "M";
      } else if (transformedValue?.toUpperCase() === "FEMALE") {
        transformedValue = "F";
      }

      setHealthCheckQuestionnaireData({
        ...healthCheckQuestionnaireData,
        sexAtBirth: { value: transformedValue },
      });
      setIsValidInput(true);
    }
  }

  function onDateOfBirthChange(value: any) {
    setIsValidInput(false);
    if (value.isValid) {
      setHealthCheckQuestionnaireData({
        ...healthCheckQuestionnaireData,
        dateOfBirth: value.data,
      });
      setIsValidInput(true);
    }
  }

  function onWeightChange(value: any) {
    setIsValidInput(false);
    if (value.isValid) {
      setHealthCheckQuestionnaireData({
        ...healthCheckQuestionnaireData,
        weight: value.data,
      });
      setIsValidInput(true);
    }
  }

  function onHeightChange(value: any) {
    setIsValidInput(false);
    if (value.isValid) {
      setHealthCheckQuestionnaireData({
        ...healthCheckQuestionnaireData,
        height: value.data,
      });
      setIsValidInput(true);
    }
  }

  function onSmokingStatusChange(value: any) {
    setIsValidInput(false);
    if (value.isValid) {
      setHealthCheckQuestionnaireData({
        ...healthCheckQuestionnaireData,
        smokingStatus: value.data,
      });
      setIsValidInput(true);
    }
  }

  function onContinue() {
    setIsValidInput(false);

    const currentQuestion = questionsToAnswer[0];
    trackEvent({
      event: "action.healthCheckQuestionAnswered",
      questionType: currentQuestion,
      source: "Health check",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: `Health check prescan questionnaire: ${currentQuestion}`,
    });

    if (questionsToAnswer.length === 1) {
      onComplete(healthCheckQuestionnaireData);
    }

    onNextQuestion(questionsToAnswer.length);

    setQuestionsToAnswer((prevQuestions: any) => prevQuestions.slice(1));
  }

  function onSkipQuestion() {
    setIsValidInput(false);

    const currentQuestion = questionsToAnswer[0];
    trackEvent({
      event: "action.healthCheckQuestionSkipped",
      questionType: currentQuestion,
      source: "Health check",
    });

    if (questionsToAnswer.length === 1) {
      onComplete(healthCheckQuestionnaireData);
    }

    onNextQuestion(questionsToAnswer.length);

    setQuestionsToAnswer((prevQuestions: any) => prevQuestions.slice(1));
  }

  useEffect(() => {
    const currentQuestion = questionsToAnswer[0];

    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: `Health check prescan questionnaire: ${currentQuestion}`,
    });

    trackPageViewV2({
      pageName: "Health:Health check prescan questionnaire",
      pageSubSection1: "Health",
      pageSubSection2: `Health: ${currentQuestion}`,
      pageCategory: "Health",
    });
  }, [questionsToAnswer]);

  return (
    <>
      {isHealthProfileLoading && <FullscreenLoadingIndicator />}
      {isHealthProfileLoading === false && (
        <Stack
          spacing={2}
          p={2}
          justifyContent="flex-end"
          sx={{ height: "100%" }}
        >
          <FaceIconBrandLarge />

          {getQuestionTypeComponent()}

          <Button variant="text" onClick={onModalOpen} size="small">
            {t("WhyAreWeAskingYouThisModal.title")}
          </Button>

          <PreScanQuestionnaireMoreInfoModal
            isOpen={isModalOpen}
            onClose={onModalClose}
          />
          <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
            <Button fullWidth onClick={onSkipQuestion} variant="outlined">
              {t("common.skipQuestion")}
            </Button>
            <Button fullWidth disabled={!isValidInput} onClick={onContinue}>
              {t("common.nextButton")}
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
}
