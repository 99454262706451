import {
  trackEvent,
  trackUserInteraction,
  trackPageViewV2,
} from "@/services/analytics-adapter";
import {
  ServiceOfferingUsage,
  ServiceOfferingUsageEnum,
} from "@/services/core-api-adapter";
import { FaceIconBrandLarge } from "@/theme/icons";
import { convertPxToRem } from "@/utils";
import { Stack, Typography, ListItem, Divider, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";
import CircledBackButton from "@/components/CircledBackButton";
import IconLoader from "@/components/IconLoader";

interface AvailableHealthChecksProps {
  needsMedicalAssistance: boolean;
  serviceOfferingUsageList: ServiceOfferingUsage[];
  availableServiceOfferingUsageCount: number;
  onContinue?: () => void;
  onBack?: () => void;
}

function NoHealthCheckNote() {
  const { t } = useTranslation();

  return (
    <Stack
      alignItems="center"
      textAlign="center"
      fontSize={14}
      bgcolor="accent2.lightest"
      borderRadius={3}
      my={2}
      py={2}
      px={10}
      spacing={1}
    >
      <Stack>
        <IconLoader
          icon="SmilingInverseFaceInRoundedSquareIcon"
          sx={{
            color: "neutral.50",
            fontSize: convertPxToRem(20),
            alignSelf: "center",
            mr: 0.5,
          }}
        />
        <Typography fontSize="inherit" color="primary.main" variant="h2">
          {t("FaceScanInstructions.noScansAvailable.title")}
        </Typography>
      </Stack>
      <Typography fontSize="inherit" variant="body2">
        {t("FaceScanInstructions.noScansAvailable.subtitle")}
      </Typography>
    </Stack>
  );
}

export default function AvailableHealthChecks({
  needsMedicalAssistance = false,
  onContinue,
  onBack,
  serviceOfferingUsageList,
  availableServiceOfferingUsageCount,
}: AvailableHealthChecksProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [healthCheckOptions, setHealthCheckOptions] = useState<
    ServiceOfferingUsage[]
  >([]);

  function formatDateToShortMonth(dateOption: Date) {
    const MONTHS_LIST = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateOption);
    return `${date.getDate()} ${t(("ShortMonthLabel." + MONTHS_LIST[date.getMonth()]) as any)} ${date.getFullYear()}`;
  }

  function transformAvailabilityStatus(options: ServiceOfferingUsage[]) {
    options.map((option) => {
      if (option.availabilityStatus === ServiceOfferingUsageEnum.INACTIVE) {
        option.availabilityStatus = ServiceOfferingUsageEnum.AVAILABLE_BETWEEN;
      }
    });

    return options;
  }

  function userNeedsMedicalAssistance() {
    return (
      healthCheckOptions.filter(
        (option: ServiceOfferingUsage) =>
          option.availabilityStatus === ServiceOfferingUsageEnum.ACTIVE
      ).length < 1 || needsMedicalAssistance
    );
  }

  function onNeedMedicalAssistanceHandler() {
    trackEvent({
      event: "action.getMedicalAssistanceInitiated",
      "getMedicalAssistance.source": "availableHealthChecksScreen",
    });

    trackUserInteraction({
      linkText:
        "Available Health Checks Screen | getMedicalAssistanceInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    navigate("/home/get-medical-assistance");
  }

  function isScanOptionAvailable(option: ServiceOfferingUsage) {
    return (
      option.availabilityStatus === ServiceOfferingUsageEnum.ACTIVE ||
      option.availabilityStatus ===
        ServiceOfferingUsageEnum.AVAILABLE_BETWEEN ||
      option.availabilityStatus === ServiceOfferingUsageEnum.NEXT_AVAILABLE
    );
  }

  useEffect(() => {
    const options = transformAvailabilityStatus(serviceOfferingUsageList)
      .sort((a: ServiceOfferingUsage, b: ServiceOfferingUsage) =>
        new Date(a?.availableDateRange.startDate).getTime() >
        new Date(b?.availableDateRange.startDate).getTime()
          ? 1
          : -1
      )
      .sort((a: ServiceOfferingUsage, b: ServiceOfferingUsage) =>
        new Date(a?.availableDateRange.endDate).getTime() <
        new Date(b?.availableDateRange.endDate).getTime()
          ? -1
          : 1
      );
    const option = (options.filter(
      (option) =>
        option.availabilityStatus === ServiceOfferingUsageEnum.AVAILABLE_BETWEEN
    ) || [])[0];

    if (option) {
      option.availabilityStatus = ServiceOfferingUsageEnum.NEXT_AVAILABLE;
    }
    setHealthCheckOptions(options);
  }, [serviceOfferingUsageList]);

  useEffect(() => {
    trackPageViewV2({
      pageName: "Health:Available health checks",
      pageSubSection1: "Health",
      pageSubSection2: "Health:Available health checks",
      pageCategory: "Health",
    });
  }, []);

  return (
    <>
      <Stack p={4} spacing={6} justifyContent="space-between">
        <CircledBackButton showLabel={true} onClick={onBack} />

        <Stack spacing={2}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography fontWeight={500} variant="h2">
              {availableServiceOfferingUsageCount > 0 && (
                <Trans
                  i18nKey="FaceScanInstructions.availableScansCount"
                  values={{
                    count: availableServiceOfferingUsageCount,
                  }}
                />
              )}

              {!availableServiceOfferingUsageCount &&
                t("FaceScanInstructions.noScansAvailable.title")}
            </Typography>
          </Stack>
        </Stack>

        {availableServiceOfferingUsageCount > 0 && (
          <Stack bgcolor="accent2.lightest" borderRadius={3} my={2}>
            <Stack
              component="ul"
              m={0}
              p={2}
              role="list"
              aria-label={t("MedicalAssistanceV2.consultationOptionsLabel")}
            >
              {healthCheckOptions.map((listItem: any, listItemIndex: any) => (
                <Stack key={listItem.order + listItemIndex}>
                  <ListItem
                    role="listitem"
                    sx={{
                      m: 0,
                      p: 0,
                      cursor: !isScanOptionAvailable(listItem)
                        ? "not-allowed"
                        : "initial",
                      opacity: !isScanOptionAvailable(listItem)
                        ? ".25"
                        : "inherit",
                    }}
                  >
                    <Stack sx={{ display: "flex", width: "100%" }}>
                      <Stack
                        alignItems="flex-end"
                        justifyContent="space-between"
                        direction="row"
                      >
                        <Stack direction="row" alignItems="center">
                          <IconLoader
                            icon="SmilingFaceInRoundedSquareIcon"
                            sx={{
                              color: "primary.main",
                              fontSize: convertPxToRem(20),
                              alignSelf: "center",
                              mr: 0.5,
                            }}
                          />
                          <Typography
                            color="primary.main"
                            variant="h2"
                            fontSize={convertPxToRem(14)}
                          >
                            <Trans
                              i18nKey="FaceScanInstructions.healthCheckIndexLabel"
                              values={{
                                count: listItemIndex + 1,
                              }}
                            />
                          </Typography>
                        </Stack>

                        <Stack textAlign="right" fontSize={convertPxToRem(12)}>
                          <Typography
                            color="neutral.700"
                            variant="h2"
                            fontSize="inherit"
                          >
                            <Trans
                              i18nKey={
                                `FaceScanInstructions.dateLabel.${listItem?.availabilityStatus}.title` as any
                              }
                            />
                          </Typography>

                          <Typography color="neutral.600" fontSize="inherit">
                            <Trans
                              i18nKey={
                                `FaceScanInstructions.dateLabel.${listItem.availabilityStatus}.subtitle` as any
                              }
                              values={{
                                startDate: formatDateToShortMonth(
                                  listItem.availableDateRange.startDate
                                ),
                                endDate: formatDateToShortMonth(
                                  listItem.availableDateRange.endDate
                                ),
                                expiredDate: formatDateToShortMonth(
                                  listItem.availableDateRange.endDate
                                ),
                                updatedDate: formatDateToShortMonth(
                                  listItem.scanCompletionDate
                                ),
                              }}
                            />
                          </Typography>
                        </Stack>
                      </Stack>
                      {listItemIndex + 1 < healthCheckOptions.length && (
                        <Stack display="flex" my={1} position="relative">
                          <Divider
                            sx={{
                              borderWidth: ".5px",
                              bgcolor: "grey.50",
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </ListItem>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}

        {availableServiceOfferingUsageCount > 0 && (
          <Stack fontSize={convertPxToRem(18)} spacing={2} my={2}>
            {(
              t<any, any, string[]>("FaceScanInstructions.note", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography key={String(text + index)} fontSize="inherit">
                {text}
              </Typography>
            ))}
          </Stack>
        )}

        {availableServiceOfferingUsageCount < 1 && <NoHealthCheckNote />}

        {!userNeedsMedicalAssistance() &&
          availableServiceOfferingUsageCount > 0 && (
            <Stack>
              <Button
                fullWidth
                color="primary"
                aria-label={t("FaceScanInstructions.buttonLabel.continue")}
                onClick={onContinue}
              >
                {t("FaceScanInstructions.buttonLabel.continue")}
              </Button>
            </Stack>
          )}

        {(userNeedsMedicalAssistance() ||
          availableServiceOfferingUsageCount < 1) && (
          <Button
            fullWidth
            color="primary"
            aria-label={t(
              "FaceScanInstructions.buttonLabel.getMedicalAssistance"
            )}
            onClick={onNeedMedicalAssistanceHandler}
          >
            {t("FaceScanInstructions.buttonLabel.getMedicalAssistance")}
          </Button>
        )}
      </Stack>
    </>
  );
}
